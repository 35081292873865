import {AuthPayloadDTO} from '../types';
import {TokenExchangeGrantRequestResponse} from '../types/sta';

export const convertAuthResponseToAuthDTO = (payload: TokenExchangeGrantRequestResponse): AuthPayloadDTO => {
  return {
    accessToken: payload.access_token || null,
    accessTokenExpiresAt: payload.expires_at || null,
    refreshToken: payload.refresh_token || null,
    refreshTokenExpiresAt: null,
    scopeToken: '',
    sessionID: '',
    organization: {
      url: '',
      name: '',
      loginMethods: [],
      id: 0,
    },
    user: {
      firstname: payload.identity.firstName,
      id: payload.identity.id,
      lastname: payload.identity.lastName,
      username: payload.identity.email,
      email: payload.identity.email,
      eulaStatus: payload.identity.eulaStatus,
      workStatus: payload.identity.workStatus,
    },
  };
};
