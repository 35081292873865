export const SSO_LOGIN_BUTTON_TEXT = 'Login with your organization account';
export const VERIFYING_AVAILABILITY = 'Verifying availability...';
export const NEXT = 'Next';

export const LOGIN_AUTHREGION_CA_ACCOUNT = "Your account doesn't exist in Canada";
export const LOGIN_AUTHREGION_USA_ACCOUNT = "Your account doesn't exist in the USA";
export const LOGIN_AUTHREGION_SWITCH_CA = 'Click here to switch to Canada region and try again';
export const LOGIN_AUTHREGION_SWITCH_USA = 'Click here to switch to the USA region and try again';

export const OR = 'or';

// errors

export const LOGIN_ERROR_CODE_INVALID_PASSWORD = 'InvalidPassword';
export const LOGIN_ERROR_MESSAGE_INVALID_PASSWORD = 'Invalid password. Please try again.';

export const LOGIN_ERROR_CODE_INVALID_USER = 'InvalidUsername';
export const LOGIN_ERROR_MESSAGE_INVALID_USER = 'User not found. Please try again.';

export const LOGIN_ERROR_CODE_ACCOUNT_LOCKED = 'AccountLocked';
export const LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED =
  'Account has been locked due to too many failed login attempts. Please contact Hypercare Support at +1 (877) 379-3533.';

export const LOGIN_ERROR_CODE_INVALID_CHALLENGE = 'InvalidChallenge';
export const LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE = 'Token expired, please try again.';

export const LOGIN_PAGE_SSO_ERROR_MODAL_TITLE = 'Single Sign-On Error';

export const USER_BLOCKED = 'UserBlocked';
export const USER_BLOCKED_TITLE = 'Account has been removed';
export const USER_BLOCKED_DESCRIPTION = (orgName: string) =>
  `The ${orgName} admin has removed your account from the organization. Remove it from your device or contact your admin if this was a mistake.`;
export const USER_NOT_FOUND = 'UserNotFound';
export const USER_NOT_FOUND_TITLE = 'Your login email was removed';
export const USER_NOT_FOUND_DESCRIPTION = (email: string) =>
  `The email ${email} has been removed from this account. Please login again with an existing email on your account, or contact your admin if this was a mistake.`;
export const NO_SUPPORTED_LOGIN_METHODS = 'NoSupportedLoginMethods';
export const NO_SUPPORTED_LOGIN_METHODS_TITLE = 'No supported login method';
export const USE_CORPORATE_EMAIL = 'UseCorpEmail';
export const ACCOUNT_REMOVED_FROM_ORG = 'Account has been removed from the organization';
export const NO_MATCHED_ACCOUNT = 'NoMatchedAccount';
export const CONTACT_ADMIN_FOR_HELP = 'Contact your admin if you need help.';
export const UNKNOWN_LOGIN_ACCOUNT_ERROR = 'Unknown Error occurred when signing into account';
