import ApiHelper from 'src/api';

class JoinOrgViewModel {
  nextButtonPressed;
  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    try {
      const fetchOrgByInviteCode = await ApiHelper.PublicEndpoints.fetchOrganizationByInviteCode(values.inviteCode);
      if (fetchOrgByInviteCode.success) {
        this.nextButtonPressed(values.inviteCode, fetchOrgByInviteCode.data.organizationForInviteCode.name);
      } else {
        return {error: 'Invalid invite code'};
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default JoinOrgViewModel;
