export const GQL_V2_OPERATIONS = {
  search: 'search',
  GetUser: 'GetUser',
  FetchSelfProfileNotes: 'FetchSelfProfileNotes',
  UpdateProfileNote: 'UpdateProfileNote',
  CreateProfileNote: 'CreateProfileNote',
  FetchPersonalNoteById: 'FetchPersonalNoteById',
  DeleteProfileNote: 'DeleteProfileNote',
  FetchUserProfile: 'FetchUserProfile',
  OrganizationalUnitQuery: 'OrganizationalUnitQuery',
  DeleteSelfProfileNote: 'DeleteSelfProfileNote',
  UpdateAddressPreference: 'UpdateAddressPreference',
  GetSelfUser: 'GetSelfUser',
  roleNotes: 'roleNotes',
  FavouritedRolesForSite: 'FavouritedRolesForSite',
  FetchUserProfileFields: 'FetchUserProfileFields',
  FetchSelfProfileFields: 'FetchSelfProfileFields',
  FetchProfileFieldsSchema: 'FetchProfileFieldsSchema',
  CreateProfileFields: 'CreateProfileFields',
  UpdateProfileField: 'UpdateProfileField',
  DeleteProfileField: 'DeleteProfileField',
  AdminAuditTrail: 'AdminAuditTrail',
  GetSelfOrgMember: 'GetSelfOrgMember',
  UpdateProfileFields: 'UpdateProfileFields',
  GetOrgAllowInviteStatus: 'GetOrgAllowInviteStatus',
  GetAddressLabels: 'GetAddressLabels',
  searchUsers: 'searchUsers',
  FetchPaginatedUsersInScopes: 'FetchPaginatedUsersInScopes',
  FetchPaginatedInvites: 'FetchPaginatedInvites',
  SearchUsersInScopes: 'SearchUsersInScopes',
  FetchOrganizationTotalResultCount: 'FetchOrganizationTotalResultCount',
  FetchUserAddresses: 'FetchUserAddresses',
  DeliveredMessagesWithoutChatId: 'DeliveredMessagesWithoutChatId',
  DeliveredMessages: 'DeliveredMessages',
  ReadMessages: 'ReadMessages',
  GetAccounts: 'GetAccounts',
  GetOrganizationByDomain: 'GetOrganizationByDomain',
  InviteUsersSTA: 'InviteUsersSTA',
  CreateShellAccount: 'CreateShellAccount',
};
