import React from 'react';
import {
  FETCH_MASKED_ADDRESSES_FOR_USER,
  FetchMaskedAddressesForUserInput,
  FetchMaskedAddressesForUserQueryResponse,
} from '../../gql/v2/query/FetchMaskedAddressesForUser';
import {print} from 'graphql/language/printer';
import {RESET_PASSWORD_MUTATION, ResetPasswordMutationResponse} from '../../gql/v2/mutation/ResetPasswordMutation';

export const ResetPasswordRepository = () => {
  const fetchMaskedAddressesForUser = async ({email, organizationUrl}: FetchMaskedAddressesForUserInput) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const graphql = JSON.stringify({
        query: print(FETCH_MASKED_ADDRESSES_FOR_USER),
        variables: {email, organizationUrl},
      });
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
      };

      const res = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: Promise<FetchMaskedAddressesForUserQueryResponse> = res.json();
      return data;
    } catch (err) {
      console.error(err, 'Error occurred when fetching masked addresses for user');
    }
  };

  const requestResetPasswordMutation = async ({challengeId, password}) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const graphql = JSON.stringify({
        query: print(RESET_PASSWORD_MUTATION),
        variables: {challengeId, newPassword: password},
      });
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
      };

      const res = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: Promise<ResetPasswordMutationResponse> = res.json();
      return data;
    } catch (err) {
      console.error(err, 'Error occurred when setting new password');
    }
  };

  return {fetchMaskedAddressesForUser, requestResetPasswordMutation};
};
