import gql from 'graphql-tag';

export interface IInviteResponse {
  address: string;
  __typename: string;
  message: string;
}

export const INVITE_USERS_MUTATION = gql`
  mutation InviteUsersSTA($invitees: [InviteUserDetails!]!) {
    organizationMutation {
      inviteUsers(invitees: $invitees) {
        ... on InviteUsersResponse {
          invitees {
            __typename
            ... on Error {
              message
            }
            ... on OrganizationMember {
              id
              firstName
              lastName
              addresses {
                address
                type
                access
                label
              }
            }
            ... on AddressInUse {
              address
            }
            ... on AccountBlocked {
              address
            }
          }
        }
      }
    }
  }
`;
