import React, {useEffect, useState} from 'react';
import RedirectingToADFSStep from 'src/pages/SignupPage/steps/redirecting-to-adfs.step';
import HypercareSignupRepository from 'src/pages/newSignupFlow/HypercareSignupRepository';

import {AuthContext} from 'src/auth';
import {Link, Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';
import HypercareSignupPersonalDetailsViewModel from 'src/pages/newSignupFlow/HypercareSignupPersonalDetails/HypercareSignupPersonalDetailsViewModel';
import CreateAccountViewModel from './CreateAccountViewModel';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {SignupPersonalDetailsView} from './SignUpPersonalDetailsView';
import {SignupJobTitleView} from './SignupJobTitleView';
import JoinCreateOrg from 'src/microfrontend/joinCreateOrg/JoinCreateOrg';
import JoinOrgView from './JoinOrgView';
import JoinOrgViewModel from './JoinOrgViewModel';
import CreateNewOrgViewModel from './CreateNewOrgViewModel';
import CreateNewOrgView from './CreateNewOrgView';
import {muiTheme} from 'src/styles/theme';
import {CreateAccountView} from './CreateAccountView';
import {SignupEmailView} from './SignupEmailView';
import {SignupVerifyEmailAddressView} from './SignupVerifyAddressView';
import SignupEmailViewModel from './SignupEmailViewModel';
import SignupVerifyAddressViewModel from './SignupVerifyAddressViewModel';
import {ACTIVATE_SHELL_ACCOUNT_FLOW, CHALLENGE_ID, STEP} from '../../../constants/queryParams';
import {EMAIL, ORG_URL} from '../../../constants/storageKeys';
import {Account} from '../../../types/sta';
import {HCCustomSpan, HCHeadingFour} from '../../../components/HypercareComponents';
import {AccountDiscoveryViewModel} from '../../LoginPage/sta/view-models/AccountDiscoveryViewModel';
import SignupPersonalDetailsViewModel from './SignupPersonalDetailsViewModel';
import {OrganizationViewModel} from 'src/pages/LoginPage/sta/view-models/OrganizationViewModel';
import {
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_CONTINUE_BUTTON,
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_DESCRIPTION,
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOADER_DESCRIPTION,
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOGIN_BUTTON,
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_SUB_TITLE,
  SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_TITLE,
} from '../../../constants/strings';
import {PageContainer} from 'src/pages/LoginPage/styled/login.styled';
import {CircularProgress, Grid} from '@mui/material';

const SignupPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-x: hidden;
`;

const SignupPageHypercareLogoContainer = styled.div`
  margin-top: 5%;
  width: 250px !important;
  height: 48px !important;
`;

const FooterText = styled.div`
  margin-top: -40px;
  height: 28px;
  line-height: 2em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${muiTheme.colors.greyishBrown};
  a {
    color: ${muiTheme.colors.chatTeal};
    text-decoration: none;
    font-weight: bold;
  }
`;

const SignupCoordinator = ({isLoggedIn, STALogin}) => {
  const params = new URLSearchParams(window.location.search);
  const emailFromParams = params.get(EMAIL) || '';
  const challengeIdFromParams = params.get(CHALLENGE_ID) || '';
  const orgUrlFromParams = params.get(ORG_URL) || '';
  const stepFromParams = parseInt(params.get(STEP) || '');
  const activateShellAccountFlow = params.get(ACTIVATE_SHELL_ACCOUNT_FLOW) || '';
  const orgNameFromQueryParams = params.get('orgName') || '';
  const orgIdFromQueryParams = params.get('orgId') || '';

  const [currentStep, setCurrentStep] = useState(stepFromParams ? stepFromParams : 1);

  const [autoLogin, setAutoLogin] = useState(false);
  const [auth0Id, setAuth0Id] = useState<string | null>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(emailFromParams ? emailFromParams : '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [challengeId, setChallengeId] = useState(challengeIdFromParams ? challengeIdFromParams : '');
  const [createOrgOption, setCreateOrgOption] = useState(false);
  const [isOrgBound, setIsOrgBound] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showExistingAccountsModal, setShowExistingAccountsModal] = useState(false);
  const {submitPassword, getAccessTokenforOTP} = OrganizationViewModel();
  const [orgInviteCode, setOrgInviteCode] = useState('');
  const [orgName, setOrgName] = useState(orgNameFromQueryParams ? orgNameFromQueryParams : '');
  const [orgId, setOrgId] = useState(orgIdFromQueryParams ? orgIdFromQueryParams : '');
  const [organizationUrl, setOrganizationUrl] = useState(orgUrlFromParams ? orgUrlFromParams : '');
  const [isLoginToLinkedAccountLoading, setIsLoginToLinkedAccountLoading] = useState(false);

  const {handleLoginToExistingAccountsFromSignup} = AccountDiscoveryViewModel();
  const existingAccounts = window.localStorage.getItem('associatedAccounts');
  const emailToken = window.localStorage.getItem('emailToken');

  const handleLoginToAccounts = async () => {
    setIsLoginToLinkedAccountLoading(true);

    const materialDialogActions = document.querySelector<HTMLElement>('.MuiDialogActions-root');

    if (materialDialogActions) {
      materialDialogActions.style.display = 'none';
    }
    try {
      if (existingAccounts && emailToken && emailFromParams) {
        const existingAccountArray: Account[] = JSON.parse(existingAccounts);
        const res = await handleLoginToExistingAccountsFromSignup(
          emailToken,
          emailFromParams,
          existingAccountArray,
          challengeId,
        );
        if (!res) {
          setShowExistingAccountsModal(false);
        }
      }
    } catch (e) {
      console.log(e, 'error occurred when logging in to accounts');
      window.routerHistory.push('/login');
    } finally {
      window.localStorage.removeItem('associatedAccounts');
    }
    setIsLoginToLinkedAccountLoading(false);
  };

  const changeExitModal = () => {
    setShowExitModal((prevState) => !prevState);
  };

  const handleContinueSignupEmail = (values) => {
    setChallengeId(values.challengeId);
    setEmail(values.email);
    if (values.auth0Id) {
      setAuth0Id(values.auth0Id);
    } else {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const handleContinueVerifyEmail = (values) => {
    setChallengeId(values.challengeId);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleContinuePersonalDetails = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setPhoneNumber(values.phoneNumber);
    values.organizationUrl ? setOrganizationUrl(values.organizationUrl) : setOrganizationUrl(orgUrlFromParams);
    setOrgName(values.orgName);
    setOrgId(values.orgId);
    values.currentStep || activateShellAccountFlow === 'true' ? setCurrentStep(6) : setCurrentStep(4);
  };

  const handleContinueVerifyOrgInviteCode = (inviteCode, name) => {
    setOrgInviteCode(inviteCode);
    setOrgName(name);
    setCurrentStep(6);
  };

  const handleContinueVerifyOrgName = (values) => {
    setOrgName(values);
    setCurrentStep(6);
  };

  const handleContinueJobTitle = (values) => {
    setJobTitle(values.jobTitle);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleGoBackADFS = () => {
    setCurrentStep(1);
    setAuth0Id(null);
  };

  const handleGoBack = () => {
    switch (currentStep) {
      case 1:
        window.routerHistory.push('/login');
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        setCurrentStep((prevValue) => prevValue - 1);
        break;
      default:
        break;
    }
  };

  const handleLogin = (values) => {
    setUsername(values.username);
    setPassword(values.userDetails.password);
    orgId ? setOrgId(orgId) : setOrgId(values.orgId);
    setOrganizationUrl(values.organizationUrl);
    setAutoLogin(true);
    if (values.loginMethods && values.loginMethods[0] === 'otp') {
      handleSubmitOTP(values.organizationUrl, orgId ?? values.orgId);
    } else {
      handleSubmitPassword(values.userDetails.password, values.organizationUrl, orgId ?? values.orgId);
    }
  };

  const handleSubmitPassword = async (password: string, organizationUrl: string, orgId: string) => {
    const res = await submitPassword(organizationUrl || '', email, password);
    // if (res?.error) {
    //   setPasswordError(res.error);
    //   setIsPasswordLoading(false);
    // }
    if (res?.data) {
      STALogin({name: orgName, id: orgId, url: organizationUrl}, res.data, email);
    }
  };

  const handleSubmitOTP = async (organizationUrl: string, orgId: string) => {
    const res = await getAccessTokenforOTP(challengeId, organizationUrl || '');
    // if (res?.error) {
    //   setOTPError(res.error);
    //   setIsOTPLoading(false);
    // }
    if (res?.data) {
      STALogin({name: orgName, id: orgId, url: organizationUrl}, res.data, email);
    }
  };

  const showSignupEmailView = () => {
    const EmailViewModel = new SignupEmailViewModel(handleContinueSignupEmail);
    const repository = new HypercareSignupRepository();
    EmailViewModel.repository = repository;
    return <SignupEmailView email={email} viewModel={EmailViewModel} onBackButtonClick={handleGoBack} />;
  };

  const showSignupEmailValidationView = () => {
    const EmailValidationViewModel = new SignupVerifyAddressViewModel(handleContinueVerifyEmail);
    const repository = new HypercareSignupRepository();
    EmailValidationViewModel.repository = repository;
    return (
      <SignupVerifyEmailAddressView
        inputData={{email}}
        viewModel={EmailValidationViewModel}
        onBackButtonClick={handleGoBack}
        setChallengeId={setChallengeId}
        challengeId={challengeId}
      />
    );
  };

  const showSignupPersonalDetailsView = () => {
    const AboutYourselfViewModel = new SignupPersonalDetailsViewModel(handleContinuePersonalDetails);
    const repository = new HypercareSignupRepository();
    AboutYourselfViewModel.repository = repository;

    return (
      <SignupPersonalDetailsView
        onBackButtonClick={handleGoBack}
        viewModel={AboutYourselfViewModel}
        inputData={{firstName, lastName, phoneNumber, email, challengeId, organizationUrl, orgName}}
      />
    );
  };

  const showOptionToJoinCreateOrgView = () => {
    return (
      <JoinCreateOrg
        onBackButtonClick={handleGoBack}
        onOptionOneClick={() => {
          setCurrentStep(5);
          setCreateOrgOption(false);
        }}
        onOptionTwoClick={() => {
          setCurrentStep(5);
          setCreateOrgOption(true);
        }}
      />
    );
  };

  const showJoinOrgWithCodeView = () => {
    const JoinOrgCodeViewModel = new JoinOrgViewModel(handleContinueVerifyOrgInviteCode);
    return <JoinOrgView onBackButtonClick={handleGoBack} viewModel={JoinOrgCodeViewModel} />;
  };

  const showCreateNewOrgView = () => {
    const CreateNewOrganizationViewModel = new CreateNewOrgViewModel(handleContinueVerifyOrgName);
    return <CreateNewOrgView onBackButtonClick={handleGoBack} viewModel={CreateNewOrganizationViewModel} />;
  };

  const showSignupJobTitleView = () => {
    const AboutYourselfViewModel = new HypercareSignupPersonalDetailsViewModel(handleContinueJobTitle);
    const repository = new HypercareSignupRepository();
    AboutYourselfViewModel.repository = repository;

    return (
      <SignupJobTitleView
        orgName={orgName}
        onBackButtonClick={handleGoBack}
        viewModel={AboutYourselfViewModel}
        inputData={{jobTitle}}
      />
    );
  };

  const showCreateAccountView = () => {
    const CreateAccount = new CreateAccountViewModel(handleLogin);
    const repository = new HypercareSignupRepository();
    CreateAccount.repository = repository;

    return (
      <CreateAccountView
        inputData={{
          email,
          phoneNumber,
          firstName,
          lastName,
          jobTitle,
          challengeId,
          orgInviteCode,
          orgName,
          organizationUrl,
          activateShellAccountFlow,
        }}
        viewModel={CreateAccount}
        onBackButtonClick={handleGoBack}
      />
    );
  };

  useEffect(() => {
    if (emailFromParams && stepFromParams && challengeIdFromParams && existingAccounts) {
      if (JSON.parse(existingAccounts) && currentStep !== 2) setShowExistingAccountsModal(true);
    }
  }, []);
  //
  // if (isLoggedIn) {
  //   return <Redirect to="/messenger/home" />;
  // }

  // if (autoLogin) {
  //   return (
  //     <>
  //       <AuthContext.Consumer>
  //         {({login}) => <AutoLogin login={login} username={username} password={password} />}
  //       </AuthContext.Consumer>
  //     </>
  //   );
  // }

  const getModalContentForExistingAccountModal = () => {
    if (isLoginToLinkedAccountLoading) {
      return (
        <Grid container direction="row" spacing={2}>
          {' '}
          <Grid item>
            <CircularProgress size={18} />
          </Grid>
          <Grid item>
            <HCHeadingFour color={muiTheme.colors.black}>
              {SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOADER_DESCRIPTION}
            </HCHeadingFour>
          </Grid>
        </Grid>
      );
    }
    if (existingAccounts) {
      const existingAccountArray: Account[] = JSON.parse(existingAccounts);
      return (
        <>
          <ul style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            {existingAccountArray.map((account) => {
              return (
                <li>
                  <HCCustomSpan color={'#4A4A4A'}>{account.organization.name}</HCCustomSpan>
                </li>
              );
            })}
          </ul>
          <HCCustomSpan color={'#4A4A4A'}>{SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_DESCRIPTION} </HCCustomSpan>
        </>
      );
    } else {
      return <>{'No associated accounts found.'}</>;
    }
  };

  return (
    <PageContainer>
      <SignupPageHypercareLogoContainer>
        <HypercareLogoSVG />
      </SignupPageHypercareLogoContainer>

      {currentStep === 1 && !Boolean(auth0Id) && showSignupEmailView()}
      {Boolean(auth0Id) && (
        <RedirectingToADFSStep
          onBackButtonClick={handleGoBackADFS}
          onContinue={() => null}
          inputData={{
            email,
          }}
          auth0Id={`${auth0Id}`}
        />
      )}
      {currentStep === 2 && showSignupEmailValidationView()}
      {currentStep === 3 && showSignupPersonalDetailsView()}

      {currentStep === 4 && showOptionToJoinCreateOrgView()}
      {currentStep === 5 && createOrgOption && showCreateNewOrgView()}
      {currentStep === 5 && !createOrgOption && showJoinOrgWithCodeView()}
      {currentStep === 6 && showSignupJobTitleView()}
      {currentStep === 7 && showCreateAccountView()}

      {showExitModal ? (
        <AlertModal
          id="exit-modal"
          width="xs"
          title="Leave this page?"
          titleFontSize="21px"
          subtitle="Information you have entered will not be saved if you leave this page."
          isAlertModalVisible={showExitModal}
          closeAlertModal={() => setShowExitModal(false)}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Stay',
              onClickHandler: () => changeExitModal(),
              id: 'exit-stay',
            },
            {
              type: 'primary',
              buttonLabel: 'Leave',
              onClickHandler: () => window.location.assign('/'),
              id: 'exit-leave',
            },
          ]}
        />
      ) : null}

      {showExistingAccountsModal ? (
        <AlertModal
          id="exit-modal"
          width="sm"
          title={SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_TITLE}
          titleFontSize="21px"
          subtitle={SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_SUB_TITLE}
          isAlertModalVisible={showExistingAccountsModal}
          closeAlertModal={() => setShowExistingAccountsModal(false)}
          modalContent={getModalContentForExistingAccountModal()}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_CONTINUE_BUTTON,
              onClickHandler: () => setShowExistingAccountsModal(false),
              id: 'exit-stay',
            },
            {
              type: 'primary',
              buttonLabel: SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOGIN_BUTTON,
              onClickHandler: () => handleLoginToAccounts(),
              id: 'exit-leave',
            },
          ]}
        />
      ) : null}

      {currentStep === 1 && (
        <FooterText>
          Already using Hypercare? <Link to="/login">Log in</Link>
        </FooterText>
      )}
    </PageContainer>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, STALogin}) => <SignupCoordinator STALogin={STALogin} isLoggedIn={isLoggedIn} />}
  </AuthContext.Consumer>
);
