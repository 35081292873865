import * as React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import SSORedirectLoadingPage from 'src/pages/SSORedirectLoadingPage';
import ChangePasswordPage from 'src/pages/ChangePasswordPage';
import MessengerPage from 'src/pages/MessengerPage';
import ProtectedRoute from 'src/router/ProtectedRoute';
import ResetPasswordPage from 'src/pages/ResetPasswordPage';
import LocatingPage from 'src/pages/LocatingPage';
import JoinOrganizationPage from 'src/pages/JoinOrganizationPage';
import InvitationPage from 'src/pages/ContactsPage/InvitationPage';
import SchedulingPage from 'src/pages/SchedulingPage';
import AuthIntroPage from 'src/pages/SignupPage';
import TemplateReportPage from 'src/pages/TemplateReportPage';
import DebugPage from 'src/pages/DebugPage';

import {
  ARCHIVED,
  MESSENGER,
  LOCATING,
  CHANGEPASSWORD,
  JOINORGANIZATION,
  SCHEDULING,
  CONTACTS,
  TEMPLATEREPORT,
  DEBUG,
  AUDIT_LOGS,
  AUTH_INTRO,
} from 'src/constants/routerPathName';
import PromptsManager from 'src/components/PromptsManager';
import {INTRO_DONE} from 'src/constants/storageKeys';
import HypercareLoginCoordinator from 'src/pages/LoginPage/HypercareLoginCoordinator';
import {lazy} from 'react';
import LoadingDiv from '../components/LoadingDiv';
import {SSORedirectMobile} from '../pages/SSORedirectLoadingPage/mobile';
import {SSORedirectCoordinator} from 'src/pages/SSORedirectLoadingPage/SSORedirectCoordinator';
import {IsFeatureFlagEnabled} from '../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../utils/FeatureFlags';
import {HypercareLoginPageCoordinator} from '../pages/LoginPage/sta/LoginPageCoordinator';
import {AccountSwitchPage} from '../pages/LoginPage/sta/accountSwitcher/AccountSwitchPage';
import MaintenancePage from 'src/components/MaintenancePage/MaintenancePage';
import SignupCoordinator from '../pages/newSignupFlow/STA/SignupCoordinator';
import HypercareSignupCoordinator from '../pages/newSignupFlow/HypercareSignupCoordinator';
import {MobileRedirect} from '../pages/LoginPage/sta/MobileRedirect';
import {ResetPasswordCoordinator} from '../pages/ResetPassword/ResetPasswordCoordinator';

// Hack to expose the router on the global window scope to use outside of react
class ExposeGlobalRouter extends React.Component {
  public componentDidMount() {
    if (!window.routerHistory) {
      this.setGlobalHistory();
    }
  }

  public componentDidUpdate() {
    if (!window.routerHistory) {
      this.setGlobalHistory();
    }
  }

  public render() {
    return null;
  }

  private setGlobalHistory() {
    // @ts-ignore
    const {history} = this.props;
    window.routerHistory = history;
  }
}

const AuditLogs = lazy(() => import('../pages/AuditLogs/index'));

const Router = () => {
  const STAFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const staticMaintenanceFlag = IsFeatureFlagEnabled(FeatureFlagResult.staticMaintenancePage);

  const LoginPage = !STAFlag ? HypercareLoginPageCoordinator : HypercareLoginCoordinator;
  const SignupPage = !STAFlag ? SignupCoordinator : HypercareSignupCoordinator;

  const ResetPasswordComponent = !STAFlag ? ResetPasswordCoordinator : ResetPasswordPage;

  return (
    <BrowserRouter>
      <React.Suspense fallback={<LoadingDiv />}>
        {staticMaintenanceFlag ? (
          <Route component={MaintenancePage} />
        ) : (
          <React.Fragment>
            <Route path="*" component={ExposeGlobalRouter} />
            <Route path="*" component={PromptsManager} />
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/sso" component={SSORedirectCoordinator} />
              <Route exact path="/mobileRedirect" component={MobileRedirect} />
              <Route exact path="/" component={LoginPage} />
              <Route exact path={`/${AUTH_INTRO}`} component={AuthIntroPage} />
              <Route exact path="/signup" component={SignupPage} />
              <Route exact path="/ssoredirect" component={SSORedirectLoadingPage} />
              <Route exact path="/resetpassword" component={ResetPasswordComponent} />
              <Route exact path={`/${DEBUG}`} component={DebugPage} />
              <Route exact path="/ssoredirect/mobile" component={SSORedirectMobile} />
              <Route exact path="/accountSwitch/:view" component={AccountSwitchPage} />
              <ProtectedRoute exact path={`/${JOINORGANIZATION}`} component={JoinOrganizationPage} />
              <ProtectedRoute exact path={`/${CHANGEPASSWORD}`} component={ChangePasswordPage} />
              <ProtectedRoute exact path={`/${LOCATING}`} component={LocatingPage} />
              <ProtectedRoute exact path={`/${LOCATING}/user/:userId`} component={LocatingPage} />
              <ProtectedRoute exact path={`/${MESSENGER}/:chatId`} component={MessengerPage} />
              <ProtectedRoute exact path={`/${ARCHIVED}/:chatId`} component={MessengerPage} />
              <ProtectedRoute exact path={`/${MESSENGER}/user/:userId`} component={MessengerPage} />
              <ProtectedRoute exact path={`/${ARCHIVED}/user/:userId`} component={MessengerPage} />
              <ProtectedRoute exact path={`/${SCHEDULING}`} component={SchedulingPage} />
              <ProtectedRoute exact path={`/${SCHEDULING}/requests`} component={SchedulingPage} />
              <ProtectedRoute exact path={`/${SCHEDULING}/marketplace`} component={SchedulingPage} />
              <ProtectedRoute exact path={`/${SCHEDULING}/mycalendar`} component={SchedulingPage} />
              <ProtectedRoute exact path={`/${SCHEDULING}/:actionType`} component={SchedulingPage} />
              <ProtectedRoute exact path={`/${CONTACTS}`} component={InvitationPage} />
              <ProtectedRoute exact path={`/${CONTACTS}/:userId`} component={InvitationPage} />
              <ProtectedRoute exact path={`/${AUDIT_LOGS}`} component={AuditLogs} />
              <ProtectedRoute exact path={`/${TEMPLATEREPORT}`} component={TemplateReportPage} />
              <Redirect to="/login" />
            </Switch>
          </React.Fragment>
        )}
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
