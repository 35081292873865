import React, {useContext, useEffect, useState} from 'react';
import HypercareAuthRegionContext from '../../../../contexts/HypercareLoginCoordinatorContext';
import {AccountSelectionEditModeTitle, AccountSelectionTitle} from '../../../../microfrontend/login/SavedAccountsTitle';
import {LoginContainer, RowItemContainer, RowItemsRightSide} from '../../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../../microfrontend/login/SavedAccountOrganizationRowItems';
import {AddButtonV2} from '../../../../microfrontend/svgs/AddButtonV2';
import {HCLabelOne} from '../../../../components/HypercareComponents';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../../constants/strings';
import {CloseButtonV2} from '../../../../microfrontend/svgs/CloseButtonV2';
import {GearIcon} from '../../../../svgs/GearIcon';
import {getParsedAuthRegion} from '../../../../utils/localStorageHandler';
import {
  AUTH_INFO,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  INTRO_DONE,
  ORGANIZATION_ACCOUNTS_DATA,
} from '../../../../constants/storageKeys';
import {
  DomainOrganization,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrgLoginMethods,
  OrgViews,
  WebViewCallBacks,
} from '../../../../types/sta';
import {toast} from 'react-toastify';
import {localStorageService} from '../../../../services/localStorageService';
import {AuthPayloadDTO, STALogin} from '../../../../types';
import {AccountSelectionViewModel} from '../view-models/AccountSelectionViewModel';
import {checkIfUserIsSignedIn} from '../../../../utils/userHelper/userUtils';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {LogoutViewModel} from '../view-models/LogoutViewModel';
import {LogoutAllAccountsModal} from '../components/LogoutAllAccountsModal';
import {AccountLogoutModal} from '../components/AccountLogoutModal';
import {callNative} from '../../../../nativeBridge';
import {SuggestedOrganizationsList} from './SuggestedOrganizationList';
import {muiTheme} from 'src/styles/theme';
import {LogoutOrContinueModal} from '../components/LogoutOrContinueModal';
import {logoutAllSavedAccounts} from '../../../../utils/sta/staUtils';
import HypercareErrorModalContext from '../../../../contexts/ErrorModalContext';
import {
  CONTACT_ADMIN_FOR_HELP,
  NO_SUPPORTED_LOGIN_METHODS,
  NO_SUPPORTED_LOGIN_METHODS_TITLE,
  UNKNOWN_LOGIN_ACCOUNT_ERROR,
  USER_BLOCKED,
  USER_BLOCKED_DESCRIPTION,
  USER_BLOCKED_TITLE,
  USER_NOT_FOUND,
  USER_NOT_FOUND_DESCRIPTION,
  USER_NOT_FOUND_TITLE,
} from '../../../../constants/login';

interface IAccountSelectionViewProps {
  handleNextStep: () => void;
  STALogin: STALogin;
  showOtpView: (isMethodSwitchable?: boolean) => void;
  showPasswordView: (isMethodSwitchable?: boolean) => void;
  loginWithAuth0: (auth0Id: string) => void;
  setCurrentChallengeId: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSelectedOrg: React.Dispatch<React.SetStateAction<CurrentSelectedOrganization>>;
  savedAccountsData: AuthPayloadDTO[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<AuthPayloadDTO[]>>;
}

export const AccountSelectionView = ({
  handleNextStep,
  STALogin,
  showOtpView,
  showPasswordView,
  loginWithAuth0,
  setCurrentChallengeId,
  setEmail,
  setCurrentSelectedOrg,
  savedAccountsData,
  setSavedAccountsData,
}: IAccountSelectionViewProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
  const {setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentRegion = getParsedAuthRegion();
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<AuthPayloadDTO | null>(null);
  const [loginToAccountLoader, setLoginToAccountLoader] = useState(false);
  const {
    handleLoginToClickedAccount,
    retrieveSuggestedOrganizations,
    suggestedOrganizationClick,
    handleSuggestedOrganizationFlow,
  } = AccountSelectionViewModel();
  const {logoutOfAccount, removeAccount} = LogoutViewModel();
  const [suggestedOrganizations, setSuggestedOrganizations] = useState<DomainOrganization[]>([]);
  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const {errorModalOpenStatus, setErrorModalOpenStatus, errorModalDetails, setErrorModalDetails} =
    useContext(HypercareErrorModalContext);

  const [showLogoutOrContinueModal, setShowLogoutOrContinueModal] = useState(false);

  const handleSwitchRegion = () => {
    setChangeRegionModalOpenStatus(true);
  };

  const handleOpenLogoutAllAccountsModal = () => {
    setShowLogoutAllAccountsModal(true);
  };

  const handleOpenRemoveAccountModal = (organization: AuthPayloadDTO) => {
    let isUserSignedIn = checkIfUserIsSignedIn(organization);

    setCurrentSelectedAcc(organization);
    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success && removeAccountResponse?.data?.savedOrganizations) {
      setSavedAccountsData(removeAccountResponse?.data.savedOrganizations);
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
    setShowRemoveAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success && logoutResponse?.data?.savedOrganizations) {
      setSavedAccountsData(logoutResponse?.data.savedOrganizations);
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(INTRO_DONE);
    localStorageService.removeItem(AUTH_INFO);
    setSavedAccountsData([]);
    toast.info(`All accounts have been removed from the browser`);
  };

  useEffect(() => {
    if (encodedDataFromMobile) {
      const selectedAcc = encodedDataFromMobile.savedOrganizations.find((acc) => {
        return acc.user.id === encodedDataFromMobile.selectedAccountUserId;
      });

      if (!selectedAcc) {
        return;
      }
      handleLoginToAccount(selectedAcc);

      const newData = encodedDataFromMobile;
      newData.selectedAccountUserId = '';

      window.localStorage.setItem('encodedDataFromMobileClients', JSON.stringify(newData));
    }
  }, []);

  useEffect(() => {
    if (encodedDataFromMobile && encodedDataFromMobile.selectedOpenDoorOrganization) {
      const {email, domainOrganization} = encodedDataFromMobile.selectedOpenDoorOrganization;
      handleSuggestedOrganizationFlow(email, domainOrganization);
    }
  }, []);

  useEffect(() => {
    const fetchSuggestedOrganizations = async () => {
      const organizations = await retrieveSuggestedOrganizations();

      if (organizations && organizations.length > 0) {
        setSuggestedOrganizations(organizations);
      }
    };

    fetchSuggestedOrganizations();
  }, []);

  const handleLoginToAccount = async (savedOrg: AuthPayloadDTO) => {
    setLoginToAccountLoader(true);
    setCurrentSelectedOrg(savedOrg.organization);
    let isUserSignedIn = checkIfUserIsSignedIn(savedOrg);

    if (!isUserSignedIn) {
      const res = await handleLoginToClickedAccount(savedOrg);

      console.log(res, 'res');
      if (res && 'error' in res) {
        callNative(WebViewCallBacks.REPORT_ERROR, {error: res.error});
        toast.error(res.error);

        let errorTitle = '';
        let errorDescription = '';

        switch (res.error) {
          case USER_NOT_FOUND:
            errorTitle = USER_NOT_FOUND_TITLE;
            errorDescription = USER_NOT_FOUND_DESCRIPTION(savedOrg.user.email || savedOrg.user.username || '');
            return;

          case USER_BLOCKED:
            errorTitle = USER_BLOCKED_TITLE;
            errorDescription = USER_BLOCKED_DESCRIPTION(savedOrg.organization.name);
            return;

          case NO_SUPPORTED_LOGIN_METHODS:
            errorTitle = NO_SUPPORTED_LOGIN_METHODS_TITLE;
            errorDescription = CONTACT_ADMIN_FOR_HELP;
            return;

          default:
            errorTitle = UNKNOWN_LOGIN_ACCOUNT_ERROR;
            errorDescription = CONTACT_ADMIN_FOR_HELP;
            break;
        }

        setErrorModalDetails({
          title: errorTitle,
          description: errorDescription,
        });
        setErrorModalOpenStatus(true);
      }
      if (res && 'auth0Id' in res) {
        loginWithAuth0(res.auth0Id);
      }

      switch (res?.screen) {
        case OrgLoginMethods.OTP:
          res.challengeId && setCurrentChallengeId(res.challengeId);
          showOtpView(res.isMethodSwitchable && true);
          setEmail(savedOrg.user.email || savedOrg.user.username || '');
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
          return;
        case OrgLoginMethods.PASSWORD:
          setEmail(savedOrg.user.email || savedOrg.user.username || '');
          showPasswordView(res.isMethodSwitchable && true);
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
          return;
        default:
          break;
      }
    } else {
      await STALogin(savedOrg.organization, savedOrg, savedOrg.user.email || savedOrg.user.username || '');
    }
    setLoginToAccountLoader(false);
  };

  const handleSuggestedOrganizationClick = async (suggestedOrganization: DomainOrganization) => {
    const res = await suggestedOrganizationClick(suggestedOrganization);
  };

  const handleLogout = () => {
    if (currentCacheData) {
      const data = logoutAllSavedAccounts(currentCacheData);
      setSavedAccountsData(data);
      toast.success('Successfully logged out of each account!');
    }
    setShowLogoutOrContinueModal(false);
  };

  const handleLogoutAndRemove = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(AUTH_INFO);
    setSavedAccountsData([]);
    window.location.assign(`/`);
    setShowLogoutOrContinueModal(false);
    toast.info(`Successfully logged out from all accounts`);
  };

  return (
    <>
      <LoginContainer loading={loginToAccountLoader ? 'true' : undefined} width={'550px'}>
        {isEditMode ? (
          <AccountSelectionEditModeTitle title={'Manage accounts'} onClick={() => setIsEditMode(false)} />
        ) : (
          <AccountSelectionTitle handleSwitchRegion={handleSwitchRegion} currentAuthRegion={currentRegion} />
        )}
        <RowItemContainer>
          {savedAccountsData?.map((savedOrg) => (
            <SavedAccountOrganizationRowItems
              key={savedOrg.user.id}
              user={savedOrg.user}
              organization={savedOrg.organization}
              isSignedIn={checkIfUserIsSignedIn(savedOrg)}
              isEditMode={isEditMode}
              handleRowClick={() => handleLoginToAccount(savedOrg)}
              rightSideContent={
                isEditMode ? (
                  <RowItemsRightSide onClick={() => handleOpenRemoveAccountModal(savedOrg)}>
                    <CloseButtonV2 />
                  </RowItemsRightSide>
                ) : (
                  <></>
                )
              }
            />
          ))}
        </RowItemContainer>
        <SavedAccountActionButtons
          onIconClick={() => setShowLogoutOrContinueModal(true)}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
              {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
            </HCLabelOne>
          }
        />
        {isEditMode ? (
          <SavedAccountActionButtons
            icon={<CloseButtonV2 />}
            onIconClick={handleOpenLogoutAllAccountsModal}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
              </HCLabelOne>
            }
          />
        ) : (
          <SavedAccountActionButtons
            icon={<GearIcon />}
            onIconClick={() => setIsEditMode(true)}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
              </HCLabelOne>
            }
          />
        )}
        {showLogoutAllAccountsModal && (
          <LogoutAllAccountsModal
            isOpen={showLogoutAllAccountsModal}
            setIsOpen={setShowLogoutAllAccountsModal}
            handleOnClick={handleRemoveAllAccountsOnDevice}
          />
        )}
        {showLogoutAccountModal && (
          <AccountLogoutModal
            title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name})`}
            subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
            isOpen={showLogoutAccountModal}
            setIsOpen={setShowLogoutAccountModal}
            modalButtons={[
              {
                type: 'primary',
                buttonLabel: 'Logout & Remove',
                onClickHandler: handleRemoveAccount,
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Logout',
                onClickHandler: handleLogoutOfAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
        {showRemoveAccountModal && (
          <AccountLogoutModal
            title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name}) from browser?`}
            subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
            isOpen={showRemoveAccountModal}
            setIsOpen={setShowRemoveAccountModal}
            modalButtons={[
              {
                type: 'secondary',
                buttonLabel: 'Cancel',
                onClickHandler: () => setShowRemoveAccountModal(false),
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Remove',
                onClickHandler: handleRemoveAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
      </LoginContainer>
      {suggestedOrganizations.length > 0 && (
        <SuggestedOrganizationsList
          organizations={suggestedOrganizations}
          handleSuggestedOrganizationClick={handleSuggestedOrganizationClick}
        />
      )}
      {showLogoutOrContinueModal && (
        <LogoutOrContinueModal
          open={showLogoutOrContinueModal}
          onRequestClose={() => setShowLogoutOrContinueModal(false)}
          onHandleContinue={() => handleNextStep()}
          onHandleLogoutAndRemove={() => handleLogoutAndRemove()}
          onHandleLogout={() => handleLogout()}
        />
      )}
    </>
  );
};
