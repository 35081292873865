import {
  AUTH_INFO,
  CURRENT_ORGANIZATION,
  CURRENT_SELECTED_ORGANIZATION,
  EMAIL,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  HYPERCARE_REGION,
  HYPERCARE_USERNAME,
  INTRO_DONE,
  MOBILE_CLIENT_ID,
  MOBILE_DEVICE_ID,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../constants/storageKeys';
import {FeatureFlagResult} from '../utils/FeatureFlags';

export type LocalStorageKey =
  | typeof AUTH_INFO
  | typeof ORGANIZATION_ACCOUNTS_DATA
  | typeof HYPERCARE_REGION
  | typeof CURRENT_ORGANIZATION
  | typeof HYPERCARE_USERNAME
  | typeof SSO_VENDOR
  | typeof INTRO_DONE
  | typeof ORG_URL
  | keyof typeof FeatureFlagResult
  | typeof EMAIL
  | typeof CURRENT_SELECTED_ORGANIZATION
  | typeof ENCODED_DATA_FROM_MOBILE_CLIENTS
  | typeof MOBILE_CLIENT_ID
  | typeof MOBILE_DEVICE_ID;

interface LocalStorageService {
  getItem<T>(key: LocalStorageKey): T | null;
  setItem<T>(key: LocalStorageKey, value: T): void;
  removeItem(key: LocalStorageKey): void;
}

function isValidJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const localStorageService: LocalStorageService = {
  getItem: (key) => {
    const item = localStorage.getItem(key);

    if (item && isValidJson(item)) {
      return JSON.parse(item);
    }

    return item || null;
  },
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
};
