import React from 'react';
import styled from '@emotion/styled';
import {HCHeadingTwo, HCLabelOne} from '../../../components/HypercareComponents';
import {PrimaryButton} from '../../../styles/styled-components/StyledMaterialComponents';
import {muiTheme} from '../../../styles/theme';
import {ResetPasswordStyledButton, ResetPasswordStyledDescription} from '../styles/resetpassword.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

interface CustomStatusMessageProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText: React.ReactNode;
  onBackButtonClick: () => void;
  hideButton?: boolean;
  alternateText?: string | null;
}

export const CustomStatusMessage = ({
  icon,
  title,
  description,
  buttonText,
  onBackButtonClick,
  hideButton = false,
  alternateText = null,
}: CustomStatusMessageProps) => (
  <Container>
    {icon}
    <HCHeadingTwo color={muiTheme.colors.black}>{title}</HCHeadingTwo>
    <ResetPasswordStyledDescription>
      <HCLabelOne color={muiTheme.colors.text}>{description}</HCLabelOne>
    </ResetPasswordStyledDescription>
    {!hideButton && (
      <ResetPasswordStyledButton onClick={onBackButtonClick}>{alternateText || buttonText}</ResetPasswordStyledButton>
    )}
  </Container>
);
