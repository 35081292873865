import React from 'react';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {OK} from '../../../../constants/strings';

export interface IErrorModalProps {
  title: string;
  description: string;
  isOpen: true;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErrorModal = ({title, description, isOpen, setIsOpen}: IErrorModalProps) => {
  return (
    <AlertModal
      width="sm"
      title={title}
      titleFontSize="21px"
      subtitle={description}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={[
        {
          type: 'primary',
          buttonLabel: OK,
          onClickHandler: () => setIsOpen(false),
          id: 'cancel-btn',
        },
      ]}
    />
  );
};
