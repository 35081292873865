import {
  ActionButtonsContainer,
  IconWrapper,
  OrganizationWithNoLogoWrapper,
  RowItems,
  RowItemsLeftInfo,
  RowItemsLeftSide,
} from '../styled/login.styled';
import {HCLabelFour, HCLabelOne, HCTextContext} from '../../components/HypercareComponents';
import {muiTheme} from '../../styles/theme';
import WarningAltIcon from '../../svgs/WarningAltIcon';
import React from 'react';
import {Organization, User} from '../types/login.types';

interface SavedAccountActionButtonsProps {
  icon: React.ReactNode;
  onIconClick: () => void;
  description: React.ReactNode;
}
interface SavedAccountOrganizationRowItemsProps {
  user: User;
  organization: Organization;
  isSignedIn: boolean;
  isEditMode: boolean;
  handleRowClick: () => void;
  rightSideContent: React.JSX.Element;
}

export const SavedAccountActionButtons = ({icon, onIconClick, description}: SavedAccountActionButtonsProps) => (
  <ActionButtonsContainer onClick={onIconClick}>
    <IconWrapper>{icon}</IconWrapper>
    {description}
  </ActionButtonsContainer>
);

export const SavedAccountOrganizationRowItems = ({
  user,
  organization,
  isSignedIn,
  isEditMode,
  handleRowClick,
  rightSideContent,
}: SavedAccountOrganizationRowItemsProps) => (
  <RowItems onClick={!isEditMode ? handleRowClick : () => null}>
    <RowItemsLeftSide>
      <IconWrapper>
        {organization.imageURL ? (
          <img src={organization.imageURL} width="40px" height="40px" alt={'org logo'} />
        ) : (
          <OrganizationWithNoLogoWrapper>{organization.name[0]}</OrganizationWithNoLogoWrapper>
        )}
      </IconWrapper>
      <RowItemsLeftInfo>
        <HCTextContext lineHeight={'24px'} color={muiTheme.colors.black}>
          {`${user.firstname || ''} ${user.lastname || ''}`}
        </HCTextContext>
        <HCTextContext lineHeight={'24px'} color={muiTheme.colors.text}>
          {organization.name}
        </HCTextContext>
        {!isSignedIn && (
          <span style={{display: 'flex', gap: '4px', justifyContent: 'center', alignItems: 'center'}}>
            <HCLabelFour color={muiTheme.colors.textLightTertiary} lineHeight={'16px'}>
              Signed out
            </HCLabelFour>
            <WarningAltIcon width={12} height={12} />
          </span>
        )}
      </RowItemsLeftInfo>
    </RowItemsLeftSide>
    {rightSideContent}
  </RowItems>
);
