import gql from 'graphql-tag';

export interface FetchMaskedAddressesForUserInput {
  email: string;
  organizationUrl: string;
}

export interface MaskedAddresses {
  id: string;
  address: string;
}

export interface FetchMaskedAddressesForUserQueryResponse {
  data: {
    user: FetchMaskedAddressesResult;
  };
}

type FetchMaskedAddressesResult =
  | {
      __typename: 'PublicUser';
      maskedAddresses: {
        id: string;
        address: string;
      }[];
    }
  | ErrorResult;

type ErrorResult =
  | {__typename: 'UserNotFound'; message: string}
  | {__typename: 'OrganizationNotFound'; message: string};

export const FETCH_MASKED_ADDRESSES_FOR_USER = gql`
  query GetMaskedAddressesForUser($email: String!, $organizationUrl: String!) {
    user(email: $email, organizationUrl: $organizationUrl) {
      ... on Error {
        __typename
        message
      }
      ... on PublicUser {
        maskedAddresses {
          id
          address
        }
        __typename
      }
    }
  }
`;
