import gql from 'graphql-tag';
import {DomainOrganization} from '../../../types/sta';

export interface FetchOrganizationsByDomainsResponse {
  data: {
    organizationsForDomains: DomainOrganization[];
  };
}

export const FETCH_ORGANIZATIONS_BY_DOMAINS_QUERY = gql`
  query FindOrganizationsByDomains($domains: [String!]!) {
    organizationsForDomains(domains: $domains) {
      ...OrganizationFragment
    }
  }
  fragment OrganizationFragment on Organization {
    __typename
    domains
    name
    id
    loginMethods
    imageURL
    url
  }
`;
