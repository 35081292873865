import React, {createContext} from 'react';

interface IErrorModalDetails {
  title: string;
  description: string;
}

interface IErrorModalModalContext {
  errorModalOpenStatus: boolean;
  setErrorModalOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
  errorModalDetails: IErrorModalDetails;
  setErrorModalDetails: React.Dispatch<React.SetStateAction<IErrorModalDetails>>;
}

const ErrorModalContext = createContext<IErrorModalModalContext>({
  errorModalOpenStatus: false,
  setErrorModalOpenStatus: () => {},
  errorModalDetails: {title: '', description: ''},
  setErrorModalDetails: () => {},
});

export default ErrorModalContext;
