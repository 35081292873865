import React from 'react';
import styled from '@emotion/styled';
import InfoIcon from 'src/svgs/Info';
import WarningAltIcon from 'src/svgs/WarningAltIcon';
import {HCBodySpan, HCLargeBoxDescription} from 'src/components/HypercareComponents';
import ConflictIcon from 'src/svgs/ConflictIcon';
import ErrorIcon from 'src/svgs/ErrorIcon';
import CheckMarkRound from 'src/svgs/CheckMarkRound';
import {muiTheme} from '../../styles/theme';

type BannerMessageType = 'info' | 'warning' | 'conflict' | 'error' | 'success';

const BannerMessageContainer = styled.div<{type: BannerMessageType}>`
  background: ${(props) =>
    props.type === 'info' || props.type === 'success'
      ? muiTheme.colors.etherealMoodBlue
      : props.type === 'warning'
      ? muiTheme.colors.ivory
      : props.type === 'conflict'
      ? muiTheme.colors.orangeBackground
      : muiTheme.colors.backdropRed};
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  border: ${(props) =>
    props.type === 'error'
      ? `1px solid  ${muiTheme.colors.redBorder}`
      : props.type === 'warning'
      ? `1px solid ${muiTheme.colors.orangedYellow}`
      : props.type === 'conflict'
      ? `1px solid ${muiTheme.colors.orangeBorder}`
      : 'none'};
  border-radius: 5px;
`;

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const InfoIconContainer = styled.div`
  padding: 0px;
  margin: 0px;
`;

type MessageIconProps = {
  type: BannerMessageType;
};

const MessageIcon = ({type}: MessageIconProps) => {
  switch (type) {
    case 'info':
      return (
        <InfoIcon
          width={24}
          height={24}
          circlefill={muiTheme.colors.chatTeal}
          strokefill={muiTheme.colors.white}
          stroke={muiTheme.colors.white}
        />
      );
    case 'warning':
      return <WarningAltIcon />;
    case 'conflict':
      return <ConflictIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'success':
      return <CheckMarkRound />;
    default:
      return null;
  }
};

interface BannerMessageProps {
  title?: string;
  description: string | React.ReactNode;
  type: BannerMessageType;
}

export const BannerMessage: React.FC<BannerMessageProps> = ({title, description, type}) => {
  return (
    <BannerMessageContainer type={type}>
      <InfoIconContainer>
        <MessageIcon type={type} />
      </InfoIconContainer>
      <BannerTextContainer>
        {title && (
          <HCLargeBoxDescription fontWeight={700} color={muiTheme.colors.black}>
            {title}
          </HCLargeBoxDescription>
        )}
        {typeof description === 'string' ? <HCBodySpan>{description}</HCBodySpan> : <>{description}</>}
      </BannerTextContainer>
    </BannerMessageContainer>
  );
};
