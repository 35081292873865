import React, {useState} from 'react';
import {typedUseSelector} from '../../../../redux';
import {useLocation, useParams} from 'react-router-dom';
import {OrganizationViewModel} from '../view-models/OrganizationViewModel';
import {AuthContext} from '../../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {STALogin} from '../../../../types';
import {LoginPasswordView} from '../../../../microfrontend/login/LoginPasswordView';
import {LoginOTPView} from '../../../../microfrontend/login/LoginOTPView';
import {PrimaryButton} from '../../../../styles/styled-components/StyledMaterialComponents';
import {OrgLoginMethods, OrgViews} from '../../../../types/sta';
import HypercareLogoSVG from '../../../../svgs/HypercareLogoSVG';
import {LoginPageHypercareLogoContainer, PageContainer} from '../../styled/login.styled';
import {DEBUG} from '../../../../constants/storageKeys';
import {FindUserOrganizationView} from '../login/FindUserOrganizationView';
import {LoginEmailAddressView} from '../../../../microfrontend/login/LoginEmailAddressView';
import {AccountDiscoveryFlowEmailView} from '../accountDiscovery/AccountDiscoveryCoordinator';
import {AccountDiscoveryViewModel} from '../view-models/AccountDiscoveryViewModel';

interface ISwitchAccountsProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  STALogin: STALogin;
  changeCurrentStep?: number;
}

const SwitchAccounts = ({isLoggedIn, auth0props, STALogin}: ISwitchAccountsProps) => {
  const {view} = useParams<{view: OrgViews | OrgLoginMethods}>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentSelectedAccount = typedUseSelector((state) => state.currentSelectedAccount);

  const email = queryParams.get('email') || '';
  const challengeId = queryParams.get('challengeId') || '';

  const [currentOrg, setCurrentOrg] = useState(currentSelectedAccount.organization ?? {});
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const {submitEmailAddress, submitOTP, submitPassword} = OrganizationViewModel();
  const {submitOTP: handleSubmitAccountDiscoveryOTP} = AccountDiscoveryViewModel();
  const [isLoginMethodSwitchable, setIsLoginMethodSwitchable] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);

  const [currentEmail, setCurrentEmail] = useState('');

  const handleSubmitPassword = async (value: string) => {
    setIsSubmittingPassword(true);
    const res = await submitPassword(currentOrg.url || '', email, value);

    if (res?.error) {
      setPasswordError(res.error);
    }

    if (res?.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
    setIsSubmittingPassword(false);
  };

  const handleAccountDiscoveryOTP = async (challengeId, otp, email) => {
    const res = await handleSubmitAccountDiscoveryOTP({challengeId, otp, email});
    if (res?.error) {
      setOTPError(res.error);
    }
  };
  console.log(email, 'eami');
  const handleSubmitOTP = async (value: string) => {
    if (window.localStorage.getItem('accountDiscoveryFromAccountSwitch') === 'true') {
      await handleAccountDiscoveryOTP(challengeId, value, currentEmail);
    }
    const res = await submitOTP(challengeId, value, currentOrg.url || '');

    if (res.error) {
      setOTPError(res.error.toString());
    }

    if (res.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
  };

  const showDebugMenu = () => {
    window.routerHistory.push(`/${DEBUG}`);
  };

  const resendOTPCode = () => {
    // request a new code
  };

  const handleGoBack = () => {
    window.routerHistory.push(`/`);
  };
  const {enterEmail} = AccountDiscoveryViewModel();

  const handleSubmitEmailAddress = async (value: string) => {
    setCurrentEmail(value);
    setIsSubmittingEmail(true);
    const res = await submitEmailAddress(value, currentOrg);

    console.log(res, 'res');

    if (res && 'error' in res && res?.error) {
      setEmailError(res.error);
    }
    if (res && 'auth0Id' in res) {
      await auth0props.loginWithRedirect({
        connection: res.auth0Id,
        scope: 'openid profile email',
      });
    }

    setIsSubmittingEmail(false);
    if (res && 'screen' in res) {
      switch (res.screen) {
        case OrgLoginMethods.OTP:
          window.routerHistory.push(`/accountSwitch/otp?challengeId=${'challengeId' in res && res.challengeId}`);
          return;
        case OrgLoginMethods.PASSWORD:
          window.routerHistory.push(`/accountSwitch/password?email=${value.replace('+', '%2b')}`);
          return;

        case OrgLoginMethods.LOGIN:
          'data' in res && STALogin(currentOrg, res.data, currentEmail);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmitEmail = async (email: string) => {
    setCurrentEmail(email);
    const res = await enterEmail(email);

    if (res && 'error' in res && res?.error) {
      setEmailError(res.error);
    }
    if (res && 'auth0Id' in res) {
      //loginWithAuth0(res.auth0Id);
    }

    if (res && 'screen' in res && res?.screen === OrgLoginMethods.OTP) {
      window.localStorage.setItem('accountDiscoveryFromAccountSwitch', 'true');
      window.routerHistory.push(`/accountSwitch/otp?challengeId=${res.challengeId}`);
      return;
    }
  };

  const handleGoToPreviousStep = () => {
    window.routerHistory.push(`/accountSwitch/findMyOrganization`);
  };

  const handleIsLoginMethodSwitchableClick = async (type: string) => {};

  const renderStep = () => {
    switch (view) {
      case OrgViews.EMAIL:
        return (
          <LoginEmailAddressView
            handleGoBack={handleGoToPreviousStep}
            handleNext={handleSubmitEmailAddress}
            organization={currentOrg}
            error={emailError}
            isLoading={isSubmittingEmail}
            isLoginMethodSwitchable={isLoginMethodSwitchable}
            handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
          />
        );
      case OrgLoginMethods.OTP:
        return (
          <LoginOTPView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitOTP}
            organization={currentSelectedAccount.organization}
            resend={resendOTPCode}
            isResending={false}
            error={OTPError}
            currentSelectedEmail={currentSelectedAccount?.user?.email || currentEmail || ''}
            isLoading={isOTPLoading}
          />
        );

      case OrgLoginMethods.PASSWORD:
        return (
          <LoginPasswordView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitPassword}
            organization={currentSelectedAccount.organization}
            error={passwordError}
            isLoading={isSubmittingPassword}
            isLoginMethodSwitchable={isLoginMethodSwitchable}
            handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
          />
        );

      case OrgViews.ACCOUNT_DISCOVERY:
        return (
          <AccountDiscoveryFlowEmailView
            handleOnEmailSubmit={handleSubmitEmail}
            showFindMyOrgView={() => window.routerHistory.push('/accountSwitch/findMyOrganization')}
            isLoading={false}
            error={emailError}
          />
        );

      case OrgViews.FIND_MY_ORGANIZATION:
        return (
          <FindUserOrganizationView
            handleGoBack={() => window.routerHistory.push('/')}
            handleShowEmailView={() => window.routerHistory.push('/accountSwitch/email')}
            setCurrentSelectedOrg={setCurrentOrg}
          />
        );
      default:
        return (
          <div>
            Unknown login methods
            <PrimaryButton onClick={() => window.routerHistory.push('/')}>Click to go back</PrimaryButton>
          </div>
        );
    }
  };

  return (
    <PageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>

      {renderStep()}
    </PageContainer>
  );
};

export const AccountSwitchPage = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, STALogin}) => (
        <SwitchAccounts STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} />
      )}
    </AuthContext.Consumer>
  );
};
