import {getParsedAuthRegion} from 'src/utils/localStorageHandler';
import {DEBUG} from 'src/constants/storageKeys';
import {CANADA_ABBREVIATION, EUROPE_ABBREVIATION, UNITED_STATES_ABBREVIATION} from 'src/constants/strings';

export default function getApiEndpoint(): string {
  const region = getParsedAuthRegion();
  const debugValues = JSON.parse(sessionStorage.getItem(DEBUG)!);

  let endPointUrl: string | undefined = '';

  if (debugValues) {
    endPointUrl = debugValues.appURL;
  } else {
    switch (region) {
      case CANADA_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_API_URL;
        break;
      case UNITED_STATES_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_API_URL_US;
        break;
      case EUROPE_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_API_URL_EU;
        break;
    }
  }

  // let port = process.env.REACT_APP_API_PORT ? String(process.env.REACT_APP_API_PORT) : '';
  let port = '3000';
  const url = String(endPointUrl);

  return port ? `${url}:${port}` : url;
}
