import * as Yup from 'yup';
import {Format} from 'src/utils/changePasswordInputChecker';

const equalTo = (ref: any, msg: any) => {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value === this.resolve(ref);
    },
  });
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const emailValidationSchema = Yup.string().required('Email is required').email('Please enter a correct email');

export const inviteCodeValidationSchema = Yup.string()
  .required('Organization code is required!')
  .min(2, 'A minimum length of 2 characters')
  .max(100, 'A maximum length of 100 characters');

export const organizationNameSchema = Yup.string()
  .trim()
  .required('Organization name is required!')
  .min(2, 'A minimum length of 2 characters')
  .max(100, 'A maximum length of 100 characters')
  .matches(/^[a-zA-Z0-9().\-_\s]+$/, `Invalid organization name format`);

export const usernameSchema = Yup.string()
  .trim()
  .required('User name is required!')
  .min(4, 'A minimum length of 4 characters')
  .max(30, 'A maximum length of 30 characters')
  .matches(/^[a-zA-Z0-9]+$/, 'Must be alphanumeric characters');

export const roleSchema = Yup.string().trim().required('Role is required!');

export const firstNameSchema = Yup.string()
  .trim()
  .min(1, 'A minimum length of 1 characters')
  .max(40, 'A maximum length of 40 characters')
  .matches(/^[a-zA-Z-]*$/, 'Must not contain any number')
  .required('First name is required!');

export const lastNameSchema = Yup.string()
  .trim()
  .min(1, 'A minimum length of 1 characters')
  .max(40, 'A maximum length of 40 characters')
  .matches(/^[a-zA-Z-]*$/, 'Must not contain any number')
  .required('Last name is required!');

export const passwordSchema = Yup.string()
  .min(8, 'A minimum length of 8 characters')
  .max(100, 'A maximum length of 100 characters')
  .matches(Format, 'Must include at least one special characters')
  .matches(/^\S*$/, 'Must not contain any space')
  .required('Password is required!');

export const confirmPasswordSchema = Yup.string()
  .equalTo(Yup.ref('password'), 'Passwords must match')
  .required('Password confirmation is required');

export const inviteCodeSchema = Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Must be alphanumeric characters');

export const EulaCheckboxSchema = Yup.boolean().oneOf(
  [true],
  'You must accept the end-user license agreement to sign up',
);

export const firstNameLastNameRoleSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  role: roleSchema,
});

export const usernameValidationSchema = Yup.object().shape({
  username: usernameSchema,
});

export const usernamePasswordAndEulaSchema = Yup.object().shape({
  username: usernameSchema,
  password: passwordSchema,
  // confirmPassword: confirmPasswordSchema,
  isEulaAccepted: EulaCheckboxSchema,
});

export const signUpValidationSchema = Yup.object().shape({
  username: usernameSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailValidationSchema,
  role: roleSchema,
  // phone: phoneNumberValidationSchema,
  password: passwordSchema,
  inviteCode: inviteCodeSchema,
  // confirmPassword: confirmPasswordSchema,
  isEulaAccepted: EulaCheckboxSchema,
});
