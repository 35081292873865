import React from 'react';
import SignupContainer from '../signup/SignupContainer';
import SignupHeader from '../signup/SignupHeader';
import styled from '@emotion/styled';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';
import NewNextStepButton from 'src/pages/newSignupFlow/components/NextStepButton';

interface IJoinOrgWithCodeProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  currentStep?: number;
  content?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31.522px;
`;

const JoinOrgWithCode: React.FC<IJoinOrgWithCodeProps> = ({onBackButtonClick, content, currentStep = 1}) => {
  return (
    <Container>
      <SignupContainer currentStep={currentStep}>
        <SignupHeader
          title={'Join existing organization with a code'}
          description={'Contact your Admins or colleagues to obtain this organization code.'}
          onBackButtonClick={onBackButtonClick}
        />
        {content}
      </SignupContainer>
    </Container>
  );
};

export default JoinOrgWithCode;
