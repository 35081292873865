import React from 'react';
import {Formik, FormikProps} from 'formik';
import InputLabel from '@mui/material/InputLabel';
import ErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import JoinOrgWithCode from 'src/microfrontend/joinCreateOrg/JoinOrgWithCode';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewNextStepButton from '../components/NextStepButton';

interface IJoinOrgViewProps {
  inputData?: any;
  onBackButtonClick?: any;
  viewModel?: any;
}

const JoinOrgView = ({inputData, onBackButtonClick, viewModel}: IJoinOrgViewProps) => {
  const onNextClick = (values: {inviteCode: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        organizatin_code: values.inviteCode,
      },
    });
  };

  return (
    <div>
      <JoinOrgWithCode
        onBackButtonClick={onBackButtonClick}
        currentStep={5}
        content={
          <Formik
            initialValues={{
              inviteCode: '',
            }}
            onSubmit={async (values, actions) => {
              const result = await viewModel.handleNextButtonPressed(values);
              if (result?.error) {
                actions.setFieldError('inviteCode', result.error);
              }
            }}
          >
            {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
              <form onSubmit={handleSubmit}>
                <StyledTextField
                  fullWidth={true}
                  margin="normal"
                  variant="outlined"
                  label="Invite Code"
                  value={values.inviteCode}
                  name="inviteCode"
                  placeholder="Enter Organization Code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.inviteCode && errors.inviteCode}
                  error={touched.inviteCode && Boolean(errors.inviteCode)}
                />
                <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
                  Next
                </NewNextStepButton>
              </form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default JoinOrgView;
