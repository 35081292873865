import gql from 'graphql-tag';
import {DomainOrganization} from '../../../types/sta';

export interface FetchOrganizationByDomainResponse {
  data: {
    organizationForDomain: DomainOrganization;
  };
}

export const FETCH_ORGANIZATION_BY_DOMAIN_QUERY = gql`
  query GetOrganizationByDomain($domain: String!) {
    organizationForDomain(domain: $domain) {
      ...OrganizationFragment
    }
  }

  fragment OrganizationFragment on Organization {
    __typename
    name
    imageURL
    url
    isOpenDoor
    id
    termsOfServiceURL
    privacyPolicyURL
  }
`;
