import ApiHelper from 'src/api';
import {SSOProfileForUserRequest, SSOProfileType} from 'src/types';
import {SSOProfile} from '../../gql/v2/query/ListSSOProfilesQuery';

interface resultObject {
  success: boolean;
  data?: SSOProfileForUserRequest;
  error?: any;
}

export interface HypercareLoginRepositoryProps {
  fetchSSOConfiguration: () => Promise<SSOProfileType[]>;
  checkIfAddressIsSSO: (email: string) => Promise<resultObject>;
}

class HypercareLoginRepository {
  SSOConfiguration: SSOProfile[] | null;

  checkIfAddressIsSSO = async (email: string) => {
    try {
      const result = await ApiHelper.PublicEndpoints.getSSODomainsForUsername(email);
      return result;
    } catch (err) {
      return {success: false, data: undefined, error: err};
    }
  };

  fetchSSOConfiguration = async () => {
    if (this.SSOConfiguration) {
      return this.SSOConfiguration;
    }

    try {
      const result = await ApiHelper.PublicEndpoints.listSSOProfiles();
      if (result && result?.data?.listSSOProfiles) {
        this.SSOConfiguration = result.data.listSSOProfiles;
      }
    } catch (err) {
      this.SSOConfiguration = null;
    }

    return this.SSOConfiguration;
  };
}

export default HypercareLoginRepository;
