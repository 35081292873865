import React from 'react';
import SignupContainer from '../signup/SignupContainer';
import SignupHeader from '../signup/SignupHeader';
import styled from '@emotion/styled';
import {ListItem} from '@mui/material';
import {HCLabelOne, HCLabelThree} from 'src/components/HypercareComponents';
import {ChevronRight} from 'src/svgs/ChevronRight';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';

interface IJoinCreateOrgProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  currentStep?: number;
  onOptionOneClick: () => void;
  onOptionTwoClick: () => void;
}

const Frame = styled.div`
  display: flex;
  width: 100%;
  max-width: 536px;
  padding: 12px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--Border-Light, #d8d8d8);
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
`;

const ItemFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31.522px;
`;

const JoinCreateOrg: React.FC<IJoinCreateOrgProps> = ({
  onBackButtonClick,
  onOptionOneClick,
  onOptionTwoClick,
  currentStep = 4,
}) => {
  return (
    <Container>
      <SignupContainer currentStep={currentStep}>
        <SignupHeader title={'Select an option'} onBackButtonClick={onBackButtonClick} />
        <ListItem disableGutters onClick={() => onOptionOneClick()} button disableRipple alignItems={'flex-start'}>
          <Frame>
            <ItemFrame>
              <HCLabelOne fontWeight={600}>Join an existing organization</HCLabelOne>
              <HCLabelThree>Join organization with a 6-digit access code</HCLabelThree>
            </ItemFrame>
            <ChevronRight />
          </Frame>
        </ListItem>
        <ListItem disableGutters onClick={() => onOptionTwoClick()} button disableRipple alignItems={'flex-start'}>
          <Frame>
            <ItemFrame>
              <HCLabelOne fontWeight={600}>Create a new organization</HCLabelOne>
              <HCLabelThree>If your organization is not using Hypercare yet</HCLabelThree>
            </ItemFrame>
            <ChevronRight />
          </Frame>
        </ListItem>
      </SignupContainer>
    </Container>
  );
};

export default JoinCreateOrg;
