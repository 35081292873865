import React from 'react';
import SignupContainer from './SignupContainer';
import SignupHeader from './SignupHeader';
import SignupFooter from './SignupFooter';

interface ISignupPageProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  discard?: boolean;
  currentStep: number;
  content: React.ReactNode;
  recoverAccount?: boolean;
  hideBackButton?: boolean;
}

const SignupPage: React.FC<ISignupPageProps> = ({
  title,
  description,
  onBackButtonClick,
  isDisabled,
  discard,
  currentStep,
  content,
  recoverAccount,
  hideBackButton,
}) => {
  return (
    <SignupContainer currentStep={currentStep}>
      <SignupHeader
        title={title}
        description={description}
        isDisabled={isDisabled}
        onBackButtonClick={onBackButtonClick}
        discard={discard}
        hideBackButton={hideBackButton}
      />
      {content}
      <SignupFooter recoverAccount={recoverAccount} />
    </SignupContainer>
  );
};

SignupPage.defaultProps = {
  title: 'Title',
  isDisabled: false,
  description: 'Description',
  discard: false,
  currentStep: 1,
  content: 'Fields',
  recoverAccount: false,
};

export default SignupPage;
