import {LoginContainer} from '../../../microfrontend/styled/login.styled';
import {
  CircleFilledWithGreenCheck,
  CircleIconNotFilled,
  LoginContent,
  ResetPasswordStyledButton,
} from '../styles/resetpassword.styled';
import {HCHeadingTwo, HCLabelOne} from '../../../components/HypercareComponents';
import {
  RESET_PASSWORD_CONTAINER_DESCRIPTION,
  RESET_PASSWORD_CONTAINER_SUBMIT_BUTTON_TEXT,
  RESET_PASSWORD_CONTAINER_TITLE,
} from '../../../constants/strings';

import React from 'react';
import {ResetPasswordMaskedEmailFormProps} from '../types/resetpassword.types';
import {ProgressContainer} from '../../LoginPage/styled/login.styled';
import {LinearProgress} from '@mui/material';
import {muiTheme} from '../../../styles/theme';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

export const ResetPasswordMaskedEmailForm = ({
  value,
  handleValueChange,
  maskedAddresses,
  submitForm,
  isLoadingSendEmail,
}: ResetPasswordMaskedEmailFormProps) => {
  return (
    <LoginContainer padding={'32px'} loading={isLoadingSendEmail ? 'true' : undefined}>
      {isLoadingSendEmail && (
        <ProgressContainer>
          <LinearProgress color="secondary" />
        </ProgressContainer>
      )}

      <LoginContent>
        <HCHeadingTwo color={muiTheme.colors.black}>{RESET_PASSWORD_CONTAINER_TITLE}</HCHeadingTwo>
        <HCLabelOne color={muiTheme.colors.text}>{RESET_PASSWORD_CONTAINER_DESCRIPTION}</HCLabelOne>
        <div>
          <FormControl component="fieldset">
            <RadioGroup value={value} onChange={handleValueChange}>
              {maskedAddresses?.map((address) => (
                <FormControlLabel
                  key={address.id}
                  id={address.id}
                  value={address.id}
                  control={
                    <Radio
                      disableRipple={true}
                      icon={<CircleIconNotFilled />}
                      checkedIcon={<CircleFilledWithGreenCheck />}
                    />
                  }
                  label={address.address}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <ResetPasswordStyledButton onClick={() => submitForm(value)} disabled={isLoadingSendEmail}>
            {RESET_PASSWORD_CONTAINER_SUBMIT_BUTTON_TEXT}
          </ResetPasswordStyledButton>
        </div>
      </LoginContent>
    </LoginContainer>
  );
};
