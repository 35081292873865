import {OrganizationAccountsCacheData} from '../../types/sta';
import {localStorageService} from '../../services/localStorageService';
import {AUTH_INFO, MOBILE_CLIENT_ID, MOBILE_DEVICE_ID, ORGANIZATION_ACCOUNTS_DATA} from '../../constants/storageKeys';
import {AuthPayload, AuthPayloadDTO} from '../../types';
import {ADMIN_WEB_APP_PROD_URL} from '../../constants/strings';
import {gzip, inflate} from 'pako';
import {getParsedSessionID} from '../localStorageHandler';

export const getCurrentLoggedInAccount = (cachedData: OrganizationAccountsCacheData | null) => {
  const authInfo = localStorageService.getItem<AuthPayload>(AUTH_INFO);
  return cachedData?.savedOrganizations.find(
    (account) => account.user.id === cachedData?.selectedAccountUserId && authInfo?.user.id === account.user.id,
  );
};

export const checkForLoggedOutAccounts = (cachedData: OrganizationAccountsCacheData | null) => {
  if (!cachedData) {
    return false;
  }
  return cachedData?.savedOrganizations.some((account) => !account.accessToken);
};

export const isRefreshTokenValid = (account: AuthPayloadDTO) => {
  return !!account.refreshToken;
};

export const isAccessTokenValid = (account: AuthPayloadDTO) => {
  if (!account.accessToken) return false;

  if (!account.accessTokenExpiresAt) return false;

  let accessTokenExpiry = new Date(account.accessTokenExpiresAt);

  let now = new Date();

  return accessTokenExpiry > now;
};

export const SAVED_ACCOUNTS_COOKIE = 'savedAccounts';

export const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
export const HYPERCARE_DOMAIN = '.hypercare.com';

export interface CookieOptions {
  secure?: boolean;
  crossSite?: boolean;
  partitioned?: boolean;
  domain?: string;
}

export function setCookie(name: string, value: string, expireDelay: number, options?: CookieOptions) {
  const date = new Date();
  date.setTime(date.getTime() + expireDelay);
  const expires = `expires=${date.toUTCString()}`;
  const sameSite = options && options.crossSite ? 'none' : 'strict';
  const domain = options && options.domain ? `;domain=${options.domain}` : '';
  const secure = options && options.secure ? ';secure' : '';
  const partitioned = options && options.partitioned ? ';partitioned' : '';
  document.cookie = `${name}=${value};${expires};path=/;samesite=${sameSite}${domain}${secure}${partitioned}`;
}

export const getAuthClientId = () => {
  const mobileClientId = localStorageService.getItem<string>(MOBILE_CLIENT_ID);

  return mobileClientId || `${process.env.REACT_APP_AUTH_CLIENT_ID}`;
};

export const getAuthDeviceID = () => {
  const mobileDeviceId = localStorageService.getItem<string>(MOBILE_DEVICE_ID);

  return mobileDeviceId || getParsedSessionID();
};

export const decodeBase64String = (base64EncodedString: string) => {
  if (!base64EncodedString) {
    return '';
  }
  try {
    const encodedData = window.atob(base64EncodedString);
    return JSON.parse(encodedData);
  } catch (e) {
    console.error('Error decoding string:', e);
    return '';
  }
};

export const getAdminWebAppUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return ADMIN_WEB_APP_PROD_URL;
    case 'staging':
      return 'https://admin.app.release.hypercare.com';
    default:
      return ADMIN_WEB_APP_PROD_URL;
  }
};

export const gzipBase64 = (input) => {
  // Convert string to a Uint8Array
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(input);

  // Gzip the encoded data
  const gzippedData = gzip(encodedData);

  // Convert gzipped data to base64
  const base64String = btoa(String.fromCharCode(...gzippedData));

  return base64String;
};

export const base64GzipDecode = (compressedBase64) => {
  // Convert the base64 string back to gzipped data (a Uint8Array)
  const binaryString = atob(compressedBase64);
  const charData = binaryString.split('').map((char) => char.charCodeAt(0));
  const gzippedData = new Uint8Array(charData);

  // Decompress the gzipped data
  const decompressedData = inflate(gzippedData);

  // Convert the decompressed data back to a string
  const textDecoder = new TextDecoder();
  const originalString = textDecoder.decode(decompressedData);

  return originalString;
};

export const logoutAllSavedAccounts = ({savedOrganizations}: OrganizationAccountsCacheData) => {
  const updatedOrganizations = savedOrganizations.map(({accessToken, ...rest}) => ({
    ...rest,
    accessToken: null,
    refreshToken: null,
    accessTokenExpiresAt: null,
  }));

  localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, {
    selectedAccountUserId: '',
    savedOrganizations: updatedOrganizations,
  });

  return updatedOrganizations;
};

export const sortSavedAccounts = (savedAccounts: AuthPayloadDTO[]): AuthPayloadDTO[] => {
  if (savedAccounts.length === 0) {
    return [];
  }
  return savedAccounts.sort((a, b) => {
    const orgComparison = a.organization.name.localeCompare(b.organization.name);
    if (orgComparison !== 0) return orgComparison;

    const lastNameComparison = a.user.lastname?.localeCompare(b.user.lastname);
    if (lastNameComparison !== 0) return lastNameComparison;

    return a.user.firstname.localeCompare(b.user.firstname);
  });
};
