import gql from 'graphql-tag';

export interface ResetPasswordMutationResponse {
  data: {
    resetPassword: ResetPasswordResult;
  };
}

type ResetPasswordResult = {__typename: 'PublicUser'; id: string} | ErrorResult;

type ErrorResult =
  | {__typename: 'VulnerablePassword'; message: string}
  | {__typename: 'PasswordRequirementsNotMet'; message: string}
  | {__typename: 'ChallengeNotFound'; message: string}
  | {__typename: 'ChallengeFailed'; message: string}
  | {__typename: 'ChallengeNotCompleted'; message: string}
  | {__typename: 'UserNotFound'; message: string};

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($challengeId: String!, $newPassword: String!) {
    resetPassword(challengeId: $challengeId, newPassword: $newPassword) {
      ... on PublicUser {
        id
        __typename
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
