import {LoginContainer} from '../../../microfrontend/styled/login.styled';
import {LoginContent, OptionContainer, ResetPasswordStyledButton, StyledForm} from '../styles/resetpassword.styled';
import {HCCustomSpan, HCHeadingTwo} from '../../../components/HypercareComponents';
import React, {useState} from 'react';
import {ResetPasswordEnterPasswordFormProps, ResetPasswordEnterPasswordFormValues} from '../types/resetpassword.types';
import {StyledTextField} from '../../../styles/styled-components/StyledInputField';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {ProgressContainer} from '../../LoginPage/styled/login.styled';
import {Icon, IconButton, InputAdornment, LinearProgress} from '@mui/material';
import {muiTheme} from '../../../styles/theme';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// Define validation schema with Yup
const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[!@#$%^&*]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const ResetPasswordEnterPasswordFormInitialValues: ResetPasswordEnterPasswordFormValues = {
  password: '',
  confirmPassword: '',
};

export const ResetPasswordEnterPasswordForm = ({
  submitForm,
  isLoadingSubmitPassword,
}: ResetPasswordEnterPasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Formik<ResetPasswordEnterPasswordFormValues>
      initialValues={ResetPasswordEnterPasswordFormInitialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, {setSubmitting, setFieldError}) => {
        const res = await submitForm(values.password);
        if (res?.error) {
          setFieldError('password', res.error);
        }

        setSubmitting(false);
      }}
    >
      {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
        <StyledForm onSubmit={handleSubmit}>
          <LoginContainer padding={'32px'} loading={isLoadingSubmitPassword ? 'true' : undefined}>
            {isLoadingSubmitPassword && (
              <ProgressContainer>
                <LinearProgress color="secondary" />
              </ProgressContainer>
            )}

            <LoginContent>
              <HCHeadingTwo color={muiTheme.colors.black}>Enter your new password</HCHeadingTwo>

              <div>
                <HCCustomSpan color={muiTheme.colors.greyishBrown}>Your password must contain:</HCCustomSpan>
                <OptionContainer>
                  <li>
                    <HCCustomSpan color={muiTheme.colors.greyishBrown}>A minimum length of 8 characters</HCCustomSpan>
                  </li>
                  <li>
                    <HCCustomSpan color={muiTheme.colors.greyishBrown}>
                      At least 1 special character (i.e. % or &)
                    </HCCustomSpan>
                  </li>
                  <li>
                    <HCCustomSpan color={muiTheme.colors.greyishBrown}>Both passwords must match</HCCustomSpan>
                  </li>
                </OptionContainer>
              </div>
              <FormControl component="fieldset" margin="none" size={'medium'}>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  value={values.password}
                  disabled={isSubmitting}
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Minimum 8 characters"
                  autoComplete="off"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password && errors.password}
                  error={touched.password && Boolean(errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          onClick={() => setShowPassword((prev) => !prev)}
                          style={{marginRight: -8, color: muiTheme.colors.primary}}
                        >
                          {!showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  value={values.confirmPassword}
                  name="confirmPassword"
                  label="Retype Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder="Minimum 8 characters"
                  margin="normal"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          onClick={() => setShowConfirmPassword((prev) => !prev)}
                          style={{marginRight: -8, color: muiTheme.colors.primary}}
                        >
                          {!showConfirmPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <div>
                <ResetPasswordStyledButton style={{border: 'none'}} type="submit" disabled={isSubmitting}>
                  Finish
                </ResetPasswordStyledButton>
              </div>
            </LoginContent>
          </LoginContainer>
        </StyledForm>
      )}
    </Formik>
  );
};
