import React from 'react';
import {HCLabelOne, HCTextContext} from '../../../../components/HypercareComponents';
import {
  IconWrapper,
  LoginContainer,
  OrganizationWithNoLogoWrapper,
  RowItems,
  RowItemsLeftInfo,
  RowItemsLeftSide,
  StyledUL,
} from '../../../../microfrontend/styled/login.styled';
import {SUGGESTED_ORGANIZATION_TITLE} from '../../../../constants/strings';
import {muiTheme} from '../../../../styles/theme';
import {DomainOrganization} from '../../../../types/sta';

interface SuggestedOrganizationsListProps {
  organizations: DomainOrganization[];
  handleSuggestedOrganizationClick: (org: DomainOrganization) => void;
}

export const SuggestedOrganizationsList = ({
  organizations,
  handleSuggestedOrganizationClick,
}: SuggestedOrganizationsListProps) => (
  <div style={{width: '550px'}}>
    <HCLabelOne color={muiTheme.colors.text} fontWeight={600} margin={'4px'}>
      {SUGGESTED_ORGANIZATION_TITLE}
    </HCLabelOne>
    <LoginContainer>
      <StyledUL>
        {organizations.map((organization, index) => (
          <RowItems key={index} onClick={() => handleSuggestedOrganizationClick(organization)}>
            <RowItemsLeftSide>
              <OrganizationIcon imageUrl={organization.imageURL} name={organization.name} />
              <OrganizationInfo name={organization.name} />
            </RowItemsLeftSide>
          </RowItems>
        ))}
      </StyledUL>
    </LoginContainer>
  </div>
);

const OrganizationIcon = ({imageUrl, name}) => (
  <IconWrapper>
    {imageUrl ? (
      <img src={imageUrl} width="40px" height="40px" alt={`${name} logo`} />
    ) : (
      <OrganizationWithNoLogoWrapper>{name[0]}</OrganizationWithNoLogoWrapper>
    )}
  </IconWrapper>
);

const OrganizationInfo = ({name}) => (
  <RowItemsLeftInfo>
    <HCTextContext lineHeight={'24px'} color={muiTheme.colors.text}>
      {name}
    </HCTextContext>
  </RowItemsLeftInfo>
);
