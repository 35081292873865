import gql from 'graphql-tag';

export interface SSOProfile {
  organization: {
    id: number;
  };
  auth0Id: string;
  domain: string;
  ssoVendor: string;
}

export interface ListSSOProfilesQueryResponse {
  data: {
    listSSOProfiles: SSOProfile[];
  };
}

export default gql`
  query ListSSOProfiles {
    listSSOProfiles {
      organization {
        id
        name
        imageURL
      }
      auth0Id
      domain
      ssoVendor
    }
  }
`;
