import React from 'react';

import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import ReactModal from 'react-modal';
import {HCHeadingFour, HCHeadingThree, HCLabelOne} from '../../../../components/HypercareComponents';
import {PrimaryButton, SecondaryButton} from '../../../../styles/styled-components/StyledMaterialComponents';
import {muiTheme} from '../../../../styles/theme';
import {StyledModal, WideWidthStyleModal} from '../../../../styles/styled-components/ModalStyleComponents';

const ChangeRegionModalDivider = styled(Divider)`
  border: none;
  margin-top: 16px !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
`;

const ChangeRegionButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  gap: 8px;
`;

const ChangeRegionModalBodyTextContainer = styled.div`
  margin: 12px 0 24px 0;
`;

export const LogoutAndContinueModal = styled(StyledModal)`
  z-index: 99;
  overflow-y: unset !important;
  .ReactModal__Content {
    width: 620px;
    min-width: 500px;
    max-width: 650px;
    overflow-y: auto !important;
  }
` as React.ComponentType<ReactModal.Props>;

const StyledButton = styled(SecondaryButton)`
  border: none;
  :hover {
    border: none;
  }
`;

export const LogoutOrContinueModal = (props: {
  open: boolean;
  onRequestClose: () => void;
  onHandleContinue: () => void;
  onHandleLogoutAndRemove: () => void;
  onHandleLogout: () => void;
}) => {
  return (
    <LogoutAndContinueModal
      isOpen={props.open}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={props.onRequestClose}
    >
      <HCHeadingThree id="alert-dialog-title">Logout from all accounts?</HCHeadingThree>

      <ChangeRegionModalBodyTextContainer>
        <HCLabelOne>
          We recommend logging out and remove all saved accounts before adding a new one if you’re using a shared
          device.
        </HCLabelOne>
      </ChangeRegionModalBodyTextContainer>

      <ChangeRegionModalDivider />

      <ChangeRegionButtonContainer>
        <div>
          <StyledButton onClick={props.onHandleContinue}>
            <HCHeadingFour color={muiTheme.colors.black}>Continue without logout</HCHeadingFour>
          </StyledButton>
        </div>
        <div style={{display: 'flex', gap: '8px'}}>
          <PrimaryButton backgroundColor={muiTheme.colors.errorRed} onClick={props.onHandleLogoutAndRemove}>
            <HCHeadingFour color={muiTheme.colors.white}>Log out & remove </HCHeadingFour>
          </PrimaryButton>
          <PrimaryButton backgroundColor={muiTheme.colors.errorRed} onClick={props.onHandleLogout}>
            <HCHeadingFour color={muiTheme.colors.white}>Log out</HCHeadingFour>
          </PrimaryButton>
        </div>
      </ChangeRegionButtonContainer>
    </LogoutAndContinueModal>
  );
};
