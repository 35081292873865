import React from 'react';
import {toast} from 'react-toastify';
import {FullOrganizationMember, UserAddressType} from 'src/types';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '@emotion/styled';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {MESSENGER} from 'src/constants/routerPathName';
import PagerMessageTabView from 'src/pages/LocatingPage/locating/profile-section/PagerMessageTabView';
import {Typography} from '@mui/material';
import 'src/components/index.css';
import client from 'src/apollo';
import SendPagerMessage from 'src/gql/mutation/SendPagerMessage';
import moment from 'moment';
import {PagerType, PagerTypeEvent} from 'src/constants/user';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {StyledHrefLink} from 'src/styles/styled-components/PopoverMenuComponents';
import {LEARN_MORE, SMS_MODAL_CONTENT} from 'src/constants/strings';

const PREFIX = 'PagerAlertModals';

const classes = {
  alertTitle: `${PREFIX}-alertTitle`,
  paperWidthMd: `${PREFIX}-paperWidthMd`,
};

const AlertTitle = styled(Typography)`
  .MuiTypography-root {
    margin-bottom: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const Root = styled('div')(({theme}) => ({
  [`& .${classes.paperWidthMd}`]: {
    maxWidth: '50vw',
  },
}));

const StyledToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;

interface Props {
  user: FullOrganizationMember | any;
  showCallbackPageModal: boolean;
  setShowCallbackPageModal: (boolean) => void;
  pagerSelected: UserAddressType | any;
  addressSelected?: string;
}

const PagerAlertModals = ({
  user,
  showCallbackPageModal,
  setShowCallbackPageModal,
  pagerSelected,
  addressSelected,
}: Props) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDiscardChanges, setShowDiscardChanges] = React.useState(false);
  const [pagerMessage, setPagerMessage] = React.useState('');

  const discardSubmit = () => {
    setShowDiscardChanges(false);
    setShowCallbackPageModal(false);
    setErrorMessage('');
    setIsLoading(false);
    setSubmitting(false);
    toast('Page discarded', {
      className: 'toast-discard',
      autoClose: 2000,
    });
  };

  async function tryCreateNewChat(isAlternative: boolean) {
    try {
      const newChatId = isAlternative ? await createNewChat([user]) : await createNewChat([user.workStatusProxy]);

      if (!newChatId) throw new Error('failed');
      window.routerHistory.push(`/${MESSENGER}/${newChatId}`);
    } catch (e) {
      console.error(e);
      toast.error('Failed to start new chat, please check your internet connection and try again');
    }
  }

  const handleSubmit = async () => {
    if (pagerMessage.length === 0) {
      setErrorMessage('Field cannot be empty!');
      setSubmitting(true);
    } else {
      setIsLoading(true);

      try {
        const sendPagerMessageResult = await client.mutate({
          mutation: SendPagerMessage,
          variables: {
            userId: user.id,
            address: addressSelected,
            message: pagerMessage,
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.pageUserSent,
          params: {
            user_id: user.id,
            address: addressSelected,
            pager_type:
              pagerSelected === PagerType.ALPHANUMERIC_PAGER
                ? PagerTypeEvent.ALPHANUMERIC_PAGER
                : PagerTypeEvent.NUMERIC_PAGER,
          },
        });

        if (!sendPagerMessageResult?.data?.self?.page) throw new Error('failed');
        toast(
          <StyledToastWrapper>
            <CheckCircleIcon />
            {`
              Page sent to ${user.firstName} ${user.lastName} at ${moment().format('hh:mm A')}
            `}
            {user.memberStatus !== 'shell' && (
              <a onClick={() => tryCreateNewChat(true)}>
                <u>View</u>
              </a>
            )}
          </StyledToastWrapper>,
          {
            className: 'toast-message',
            autoClose: 5000,
          },
        );
      } catch (e) {
        console.error(e);
        toast.error('Failed to send page, please check your internet connection and try again.');
      }
      setShowCallbackPageModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {showCallbackPageModal && (
        <AlertModal
          width="xs"
          title={`Send ${user.firstName} ${user.lastName} ${
            pagerSelected === PagerType.ALPHANUMERIC_PAGER ? 'an alphanumeric' : 'a numeric'
          } page at ${addressSelected}`}
          titleFontSize="21px"
          subtitle=""
          closeAlertModal={() => setShowDiscardChanges(true)}
          isAlertModalVisible={showCallbackPageModal}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowDiscardChanges(true),
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: isLoading ? 'Sending...' : 'Send',
              onClickHandler: () => handleSubmit(),
              disabled: errorMessage !== '' || isSubmitting || isLoading,
              id: 'send-btn',
            },
          ]}
          modalContent={
            <>
              <AlertTitle>
                {SMS_MODAL_CONTENT}
                <StyledHrefLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.hypercare.com/hc/en-us/articles/21198484295821-Why-can-t-I-share-health-information-via-pagers-or-SMS-"
                >
                  <span>{LEARN_MORE}</span>
                </StyledHrefLink>
              </AlertTitle>
              <PagerMessageTabView
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setPagerMessage={setPagerMessage}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
                inputType={pagerSelected}
              />
            </>
          }
        />
      )}
      {showDiscardChanges && (
        <AlertModal
          id="discard-modal"
          width="sm"
          title="Discard unsaved message?"
          titleFontSize="21px"
          subtitle="Your message will not be saved. Are you sure you want to discard this?"
          closeAlertModal={() => setShowDiscardChanges(false)}
          isAlertModalVisible={showDiscardChanges}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Nevermind',
              onClickHandler: () => setShowDiscardChanges(false),
              id: 'discard-nevermind',
            },
            {
              type: 'primary',
              buttonLabel: 'Discard',
              onClickHandler: () => discardSubmit(),
              id: 'discard-confirm',
            },
          ]}
        />
      )}
    </>
  );
};

export default PagerAlertModals;
