import React from 'react';
import styled from '@emotion/styled';
import {PrimaryButton} from '../../../styles/styled-components/StyledMaterialComponents';

export const CircleFilledWithGreenCheck = (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1817_26049)">
      <path
        d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM11.2071 15.4861C10.8166 15.8766 10.1834 15.8765 9.79293 15.486L7.34649 13.0396C7.01705 12.7101 7.01704 12.176 7.34645 11.8466C7.67589 11.5171 8.21005 11.5171 8.53951 11.8465L10.5 13.8069L15.4628 8.84465C15.7915 8.51593 16.3243 8.51513 16.654 8.84285C16.9852 9.17203 16.986 9.70764 16.6558 10.0378L11.2071 15.4861Z"
        fill="#00859A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_26049">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CircleIconNotFilled = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1817_26051)">
      <path
        d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_26051">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CircleWithCrossInMiddle = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="30.5" stroke="#FF3E55" stroke-width="3" />
    <g clip-path="url(#clip0_1817_26066)">
      <path
        d="M41.2929 24.4571C41.6834 24.0666 41.6834 23.4334 41.2929 23.0429L40.9571 22.7071C40.5666 22.3166 39.9334 22.3166 39.5429 22.7071L32 30.25L24.4571 22.7071C24.0666 22.3166 23.4334 22.3166 23.0429 22.7071L22.7071 23.0429C22.3166 23.4334 22.3166 24.0666 22.7071 24.4571L30.25 32L22.7071 39.5429C22.3166 39.9334 22.3166 40.5666 22.7071 40.9571L23.0429 41.2929C23.4334 41.6834 24.0666 41.6834 24.4571 41.2929L32 33.75L39.5429 41.2929C39.9334 41.6834 40.5666 41.6834 40.9571 41.2929L41.2929 40.9571C41.6834 40.5666 41.6834 39.9334 41.2929 39.5429L33.75 32L41.2929 24.4571Z"
        fill="#FF3E55"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_26066">
        <rect width="40" height="40" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
);

export const HorizontalLogoWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
`;

export const ResetPwdContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
`;

export const ResetPasswordStyledButton = styled(PrimaryButton)`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ResetPasswordStyledDescription = styled.div`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OptionContainer = styled.ul`
  height: auto;
  width: 100%;
  margin: 0;
`;
