import React from 'react';
import styled from '@emotion/styled';
import SwitchOrganizationDropdown from 'src/pages/MessengerPage/messenger/page-header/SwitchOrganizationDropdown';
import {connect} from 'react-redux';
import {RootState} from 'src/types';
import store from 'src/redux';
import {Link} from 'react-router-dom';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';
import {SwitchAccountDropdownContainer} from '../../../LoginPage/sta/accountSwitcher/SwitchAccountDropdownContainer';
const LeftSection = styled.div`
  flex: 1;
  display: flex;
  min-width: 300px;
  align-items: center;
  height: 40px;
`;

const LogoWrapper = styled.div`
  height: 30px;
  width: 30px;
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

const LeftSectionWrapper = () => {
  const currentOrganization = store.getState().organization;
  const STAFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);

  // TODO: Badge to show unread message from other organization
  return (
    <LeftSection>
      {!STAFlag ? (
        <SwitchAccountDropdownContainer currentOrganization={currentOrganization} />
      ) : (
        <>
          <LogoWrapper>
            <Link to="/messenger/home">
              <Logo src="/assets/hypercare-icon.svg" />
            </Link>
          </LogoWrapper>
          <SwitchOrganizationDropdown onSelect={trySwitchOrganization} currentOrganization={currentOrganization} />
        </>
      )}
    </LeftSection>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentOrganization: state.organization,
});

export default connect(mapStateToProps)(LeftSectionWrapper);
