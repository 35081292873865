import ApiHelper from 'src/api';

class CreateNewOrgViewModel {
  nextButtonPressed;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    const payload = {organizationDetails: {name: values.orgName}};
    try {
      const createOrgResult = await ApiHelper.PublicEndpoints.fetchOrganizationByName(values.orgName, 200);
      const exactMatch = createOrgResult.data?.findOrganizationByName.organizations.some(
        (org) => org.name.toLowerCase() === values.orgName.toLowerCase(),
      );
      if (!exactMatch) {
        this.nextButtonPressed(values.orgName);
      } else {
        return {error: 'An organization with this name already exists'};
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default CreateNewOrgViewModel;
